import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexTestAPI } from "../../../apis/apiColdex/ColdexTestAPI";
import {
  MESSAGE_ACTION_TYPE_GET,
  MESSAGE_CONTEXT_COLDEX,
  MESSAGE_CONTEXT_COMPANY,
  MESSAGE_ERROR_TIMEOUT,
} from "../../../utils/constants/message-constants";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";
import { Company } from "../../../utils/types/Company";
import { startLoadingCompany, setCompanies, setError } from "./companySlice";
/**
* Esta función recupera una lista de empresas de una API y envía acciones basadas en el éxito
  * o fallo de la llamada a la API.
  * @returns Una función que devuelve una función asíncrona que toma una función 'dispatch' como un
  * argumento.
 */
export const getCompanys = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingCompany());
    try {
      const { data } = await ColdexTestAPI.get<IApiResult<Array<Company>>>(
        "/adminUser/Company"
      );
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_COLDEX}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COLDEX}`,
            open: true,
          })
        );
      } else {
        dispatch(
          setCompanies({
            resultObject: data.resultObject,
            resultMessage: data.resultMessage,
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_COMPANY}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_COMPANY}`,
          open: true,
        })
      );
    }
  };
};
/**
* Esta función restablece el estado de error en una tienda Redux.
 * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
 * Funciona como un argumento.
*/
export const resetError = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(
      setError({ error: "", iserror: false, shorterror: "", open: false })
    );
  };
};
