import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPermissionXRolState } from '../../../utils/interfaces/store/IStore'
import { IPayloadSetdata, IPayloadSetError } from '../Interfaces'
const initialState: IPermissionXRolState = {
  permissionRolUser: [],
  permissionRol: [],
  isLoadingUpdate: false,
  permissionUpdate: [],
  shortMessage: '',
  resultMessage: '',
  dataError: false,
  open: false,
  isLoading: false
}

export const permissionsXRolSlice = createSlice({
  name: 'permissionXRol',
  initialState,
  reducers: {
    startPermissionLoading:(state)=>{
        state.isLoading=true
    },
    startPermissionLoadingUpdate:(state)=>{
        state.isLoadingUpdate=true
    },
    setPermission:(state,{payload}:PayloadAction<IPayloadSetdata<{response:string}>>)=>{
      state.isLoading=false;
      state.dataError = false;
      state.permissionRolUser= JSON.parse(payload.resultObject.response)
      state.resultMessage = payload.resultMessage!;
      state.isLoading=false;
    },
    
    setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
        state.isLoading = false;
        state.dataError = payload.iserror;
        state.resultMessage = payload.error;
        state.shortMessage = payload.shorterror;
        state.open=payload.open!
    },
  },
})

// Action creators are generated for each case reducer function
export const {  startPermissionLoading,setPermission,setError,startPermissionLoadingUpdate} = permissionsXRolSlice.actions

export default permissionsXRolSlice.reducer