export const HOME_ROUTE = "/"

export const ADMINISTRATION_ROUTE = '/administracion'
export const COLDEX_ROUTE = '/coldex'
export const REPORTS_ROUTE = '/reportes'
export const RESOURCES_ROUTE = 'recursos'
export const LOGIN_ROUTE= '/login'

// CHILDRENS REACT OUTLET
export const USERS_ROUTE = "usuarios"
export const USERS_COLDEX_ROUTE = "user"
export const ROLE_ROUTE = "roles"
export const COMPANIES_ROUTE = "empresas"
export const COLDEX_NEWTWORK_ROUTE = "red"
export const COLDEX_SECTORIAL_ROUTE = "sectorial"

//ROUTES MENU

export const MENU_USERS_ROUTE = "/administracion/usuarios"
export const MENU_COMPANY_ROUTE = "/administracion/empresas"
export const MENU_ROLE_ROUTE = "/administracion/roles"
export const MENU_RESOURCES_ROUTE = "/administracion/recursos"

export const MENU_COLDEX_NETWORK_ROUTE = "/coldex/red"
export const MENU_COLDEX_SECTORIAL_ROUTE = "/coldex/sectorial"
