import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IHeadersState } from "../../../utils/interfaces/store/IStore";
import { HeadersPublic } from "../../../utils/types/HeadersPublic";
import { IPayloadSetdata, IPayloadSetError } from "../Interfaces";

const initialState: IHeadersState = {
  shortMessage: "",
  resultMessage: "",
  dataError: false,
  open: false,
  isLoading: false,
  headers: {
    id: undefined,
    idColdex: 0,
    timestamp: "",
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    industry: undefined,
    companyName: undefined,
    phone: undefined,
    rol: undefined,
    acceptedContact: undefined
  }
};

export const headerColdexSlice = createSlice({
  name: "headerColdex",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    setHeaderColdex: (state,  {payload}:PayloadAction<IPayloadSetdata<HeadersPublic>>) => {
      state.isLoading = false;
      state.headers = payload.resultObject;
    },
    setReset: (state) => {
      state.headers = {
        id: undefined,
        idColdex: 0,
        timestamp: "",
        email: undefined,
        firstName: undefined,
        lastName: undefined,
        industry: undefined,
        companyName: undefined,
        phone: undefined,
        rol: undefined,
        acceptedContact: undefined
      }
    },
    setError: (state, {payload}:PayloadAction<IPayloadSetError>) => {
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    endLoading: (state) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { startLoading, setHeaderColdex, setError, endLoading, setReset } =
  headerColdexSlice.actions;

export default headerColdexSlice.reducer;
