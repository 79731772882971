import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./slices/loginUser/loginUserSlice";
import companyReducer from "./slices/company/companySlice";
import parametersReducer from "./slices/parameters/parametersSlice";
import categoryxColdexReducer from "./slices/categoryXColdex/categoryXColdex";
import pollxColdexReducer from "./slices/pollxColdex/pollxColdexConfig";
import userReducer from "./slices/userxColdex/userxColdexSlice";
import userxsecurityReducer from "./slices/securityxUserxColdex/securityxUserxColdexSlice";
import PermissionsXRolReducer from "./slices/permissionXRol/permissionXRol";
import pollTypeReducer from "./slices/pollTypes/pollTypesSlice";
import rowDataPublicReducer from "./slices/rowDataPublic/rowDataPublicColdexSlice";
import headersPublicReducer from "./slices/headersPublicColdex/HeadersPublicColdex";

export const store = configureStore({
  reducer: {
    loginuser: loginReducer,
    company: companyReducer,
    parameters: parametersReducer,
    categoryxColdex: categoryxColdexReducer,
    pollxColdex: pollxColdexReducer,
    userxcoldex: userReducer,
    userxsecurityxcoldex: userxsecurityReducer,
    headersPublic: headersPublicReducer,
    permission: PermissionsXRolReducer,
    polltypes: pollTypeReducer,
    rowDataPublic: rowDataPublicReducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
