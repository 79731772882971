
import { Action, Dispatch } from '@reduxjs/toolkit';
import { ColdexTestAPI } from '../../../apis/apiColdex/ColdexTestAPI';
import { DATE_FORMAT } from '../../../utils/constants/dateFormat';
import { MESSAGE_ERROR_TIMEOUT, MESSAGE_CONTEXT_USERXCOLDEX, MESSAGE_ACTION_TYPE_GET } from '../../../utils/constants/message-constants';
import { IApiResult } from '../../../utils/interfaces/store/IApiResponse';
import { ColdexUser } from '../../../utils/types/Coldex';
import { UserColdex } from '../../../utils/types/UserColdex';
import {  startUserxColdexLoading,setError, setColdexxUser} from './userxColdexSlice';

/**
  * Esta es una función de TypeScript React que recupera los Coldex por correo electrónico  de usuarioy envía acciones
  * basado en el resultado.
  * @param {string} email: el parámetro email es una cadena que representa la dirección de correo electrónico de un usuario.
  * Esta función se utiliza para recuperar información sobre los Coldex en función de su dirección de correo electrónico.
  * @returns Una función que toma un correo electrónico como parámetro y devuelve una función asíncrona que
  * envía acciones para comenzar a cargar un usuario desde ColdexTestAPI y luego establece resultObject
  * del usuario o establece un error si hay un tiempo de espera u otro error.
  */
export const getColdexbyEmail = (email:string) => {
    return async( dispatch:Dispatch<Action>) => {
        dispatch( startUserxColdexLoading() );
        try {
            const { data } = await ColdexTestAPI.get<IApiResult<Array<ColdexUser>>>(`adminColdex/UserxColdex/coldex/${email}?Date=${new Date().toLocaleString(DATE_FORMAT).split(" ")[0]}`);
            dispatch(setColdexxUser({resultObject:data.resultObject}));
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, open: true }));
        }
    }    
}
/**
  * Esta es una función de TypeScript React que elimina un usuario por correo electrónico mediante una llamada a la API y envía acciones para actualizar el estado en consecuencia.
  * @param {string} email: el correo electrónico del usuario que debe eliminarse del sistema Coldex.
  * @returns Una función que toma un parámetro de cadena y devuelve una función asincrónica que envía acciones para comenzar a cargar, eliminar un usuario por correo electrónico mediante una llamada a la API y envía acciones para eliminar el usuario localmente y finalizar la carga. Si hay un error, envía una acción para establecer un mensaje de error.
  */
export const resetError = () => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch(setError({ error: "", iserror: false, shorterror: "", open: false }));
    }
}