import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserxColdexState } from '../../../utils/interfaces/store/IStore'
import { ColdexUser } from '../../../utils/types/Coldex'
import { UserColdex } from '../../../utils/types/UserColdex'
import { IPayloadSetdata, IPayloadSetError } from '../Interfaces'



const initialState: IUserxColdexState = {
  shortMessage: "",
  resultMessage: "",
  dataError: false,
  open: false,
  isLoading: false,
  coldex: [],
  users: []
}

export const userxColdexSlice = createSlice({
  name: 'UserxColdex',
  initialState,
  reducers: {
    startUserxColdexLoading:(state)=>{
        state.isLoading=true
    },
    setUserxColdex:(state,{payload}:PayloadAction<IPayloadSetdata<Array<UserColdex>>>)=>{
        state.isLoading=false
        state.users = payload.resultObject;    
      },
    setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
      state.isLoading=false
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    endLoading:(state)=>{
      state.isLoading=false
    },
    setColdexxUser:(state,{payload}:PayloadAction<IPayloadSetdata< Array<ColdexUser>>>)=>{
      state.coldex=payload.resultObject;
      state.isLoading=false
    }
  },
})

// Action creators are generated for each case reducer function
export const {  startUserxColdexLoading,setUserxColdex,setError,endLoading,setColdexxUser} = userxColdexSlice.actions

export default userxColdexSlice.reducer