import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexTestAPI } from "../../../apis/apiColdex/ColdexTestAPI";
import {
  MESSAGE_ACTION_TYPE_GET,
  MESSAGE_CONTEXT_POLLTYPE,
  MESSAGE_ERROR_TIMEOUT,
} from "../../../utils/constants/message-constants";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";
import { IPolls } from "../../../utils/interfaces/store/IStore";
import { setPollType, startLoadingPollType, setError } from "./pollTypesSlice";
/**
  * Esta es una función de TypeScript React que recupera datos de tipos encuestas de una API y envía acciones según el resultado.
  * @returns Una función que devuelve una función asíncrona que toma una función de envío como argumento. La función interna realiza una llamada a la API para recuperar los datos de la encuesta y envía acciones en función del resultado.
  */
export const getPoll = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingPollType());
    try {
      const { data } = await ColdexTestAPI.get<IApiResult<Array<IPolls>>>(
        "adminColdex/PollType"
      );
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_POLLTYPE}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_POLLTYPE}`,
            open: true,
          })
        );
      } else {
        dispatch(
          setPollType({
            resultObject: data.resultObject,
            resultMessage: data.resultMessage,
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_POLLTYPE}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_POLLTYPE}`,
          open: true,
        })
      );
    }
  };
};
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(
      setError({ error: "", iserror: false, shorterror: "", open: false })
    );
  };
};
