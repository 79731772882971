import  {lazy, Suspense} from "react";
import { Route } from "react-router-dom";
import Loader from "../components/Loader/Loader";

const MainHomeView = lazy(() => import('../views/MainView/components/MainHomeView'));
const LoginView = lazy(() => import('../views/LoginView/LoginView'))
const MainView = lazy(() => import('../views/MainView/MainView'))
const PollsView = lazy(() => import('../views/PollsView/PollsView'))
const ReportsView = lazy(() => import('../views/ReportsView/ReportsView'))
const UserHomeColdexView = lazy(() => import('../views/UserView/components/UserHomeColdexView'))
const UserColdexView = lazy(() => import('../views/UserView/UserColdexView'))
const BannerView = lazy(() => import('../views/BannerView/BannerView'))

export const routes = [
  <Route path="/login" element={<Suspense fallback={<><Loader></Loader></>}><LoginView></LoginView></Suspense>} key="login" />,
  <Route path="/" element={<Suspense fallback={<><Loader></Loader></>}><MainView></MainView></Suspense>} key="main">,
  <Route path="" element={<Suspense fallback={<><Loader></Loader></>}><MainHomeView></MainHomeView></Suspense>}></Route>
    <Route path="reportes" element={<Suspense fallback={<><Loader></Loader></>}><ReportsView></ReportsView></Suspense>} key="empresas">,
    <Route path="graficos" element={<Suspense fallback={<><Loader></Loader></>}></Suspense>} key="graficos">  
    </Route>
    </Route>
    <Route path="user" key="userColdex" element={<Suspense fallback={<><Loader></Loader></>}><UserHomeColdexView></UserHomeColdexView></Suspense>} >
        <Route path="" element={<Suspense fallback={<><Loader></Loader></>}><MainHomeView></MainHomeView></Suspense>} />,  
        <Route path="coldex/:idcoldex" element={<Suspense fallback={<><Loader></Loader></>}><UserColdexView></UserColdexView></Suspense>} />,  
        <Route path="coldex/:idcoldex/:idpoll/:idcategory" element={<Suspense fallback={<><Loader></Loader></>}><PollsView/></Suspense>} />  
        <Route path="coldex/reporte/:idcoldex" element={<div></div>} key="graficos"/>  
        <Route path="banner" element={<Suspense fallback={<><Loader></Loader></>}><BannerView></BannerView></Suspense>} key="banner">,
  </Route>,
  </Route>   
  </Route>   
];
