

import { Action, Dispatch } from '@reduxjs/toolkit';
import { ColdexTestAPI } from '../../../apis/apiColdex/ColdexTestAPI';
import {  MESSAGE_ACTION_TYPE_GET, MESSAGE_CONTEXT_SECUSERXCOLDEX, MESSAGE_ERROR_TIMEOUT } from '../../../utils/constants/message-constants';
import { IApiResult } from '../../../utils/interfaces/store/IApiResponse';
import { Security } from '../../../utils/types/Security';
import {  startLoading,setUserxColdexSlice,setError} from './securityxUserxColdexSlice';

/**
  * Esta función recupera una lista de objetos de seguridad asociados con un usuario específico y una ID de Coldex de una API y envía el resultado a una tienda Redux.
  * @param {string} email: una cadena que representa el correo electrónico del usuario que se recuperará.
  * @param {number} idcoldex - El parámetro `idcoldex` es un número que representa la ID de un Coldex
  * @returns La función `getUsers` devuelve una función que toma un parámetro `dispatch` y una Promesa. La Promesa resuelve enviar una acción con el resultado de una solicitud GET, pasando un correo electrónico e idcoldex como parámetros. Si tiene éxito, el objeto de resultado de la respuesta se pasa a la acción `setUserxColdexSlice`.
  */
export const getUsers = (email:string , idcoldex:number) => {
    return async( dispatch:Dispatch<Action>) => {
        dispatch( startLoading() );
        try {
            const { data } = await ColdexTestAPI.get<IApiResult<Array<Security>>>(`adminColdex/SecurityxUserxColdex/${idcoldex}?&email=${email}`);
            dispatch(setUserxColdexSlice({resultObject:data.resultObject}));
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_SECUSERXCOLDEX}`, iserror: false, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_SECUSERXCOLDEX}`, open: true }));
        }
    }    
}
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch(setError({ error: "", iserror: false, shorterror: "", open: false }));
    }
}