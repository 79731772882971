import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CompanyState } from '../../../utils/interfaces/store/IStore';
import { Company } from '../../../utils/types/Company';
import { IPayloadSetdata, IPayloadSetError } from '../Interfaces';

const initialState: CompanyState = {
    companiesList: [],
    shortMessage: '',
    resultMessage: '',
    dataError: false,
    open: false,
    isLoading: false
}

export const companySlice = createSlice({
    name: 'Company',
    initialState,
    reducers:{
        startLoadingCompany: (state) =>{
            state.isLoading = true;
        },
        setCompanies:(state,  {payload}:PayloadAction<IPayloadSetdata<Array<Company>>>)=>{
            state.dataError = false;
            state.isLoading = false;
            state.companiesList = payload.resultObject;
            state.resultMessage = payload.resultMessage!;
        },
        setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
            state.isLoading = false;
            state.dataError = payload.iserror;
            state.resultMessage = payload.error;
            state.shortMessage = payload.shorterror!;
            state.open = payload.open!;
        }
    }
})

export const {startLoadingCompany, setCompanies,setError} = companySlice.actions

export default companySlice.reducer
