import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexTestAPI } from "../../../apis/apiColdex/ColdexTestAPI";
import {
  MESSAGE_ACTION_TYPE_GET,
  MESSAGE_CONTEXT_PARAMETERS,
  MESSAGE_ERROR_TIMEOUT,
} from "../../../utils/constants/message-constants";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";
import { Parameter } from "../../../utils/types/Parameter";
import usParameters from "../../../utils/hooks/administration/useParameters";

import {
  startParametersLoading,
  setParameters,
  setError,
} from "./parametersSlice";
/**
  * Esta es una función que recupera parámetros del API y envía acciones según el resultado.
  * @returns Una función que devuelve una función asíncrona que toma un parámetro de envío y realiza una llamada API para recuperar datos de parámetros. Luego, los datos recuperados se usan para enviar acciones para actualizar el estado con los datos de los parámetros o para establecer un mensaje de error si hubo un problema con la llamada a la API.
  */
export const getParameters = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startParametersLoading());
    try {
      const { data } = await ColdexTestAPI.get<IApiResult<Array<Parameter>>>(
        "adminUser/ValuexParameter"
      );
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_PARAMETERS}`,
            iserror: false,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_PARAMETERS}`,
            open: true,
          })
        );
      } else {
        const {
          parametersFormUser,
          parametersTypeRole,
          parameterCompanyType,
          parameterCompanyTDOC,
          parametersColdexType,
          parameterSectionType,
          parameterResourcesForm,
          parameterResourcesType,
          parameterInconsType,
          parametersSectors,
        } = usParameters(data.resultObject);
        dispatch(
          setParameters({
            resultObject: {
              parametersFormUser,
              parametersTypeRole,
              parameterCompanyType,
              parameterCompanyTDOC,
              parametersColdexType,
              parameterSectionType,
              parameterResourcesForm,
              parameterResourcesType,
              parameterInconsType,
              parametersSectors,
            },
            resultMessage: data.resultMessage,
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_PARAMETERS}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_PARAMETERS}`,
          open: true,
        })
      );
    }
  };
};
/**
* Esta función restablece el estado de error en una tienda Redux.
 * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
 * Funciona como un argumento.
*/
export const resetError = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(
      setError({ error: "", iserror: false, shorterror: "", open: false })
    );
  };
};
