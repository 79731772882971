import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexTestAPI } from "../../../apis/apiColdex/ColdexTestAPI";
import {
  MESSAGE_ACTION_TYPE_GET,
  MESSAGE_CONTEXT_Permissions,
  MESSAGE_ERROR_TIMEOUT,
} from "../../../utils/constants/message-constants";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";
import { Generic } from "../../../utils/interfaces/store/IStore";
import {
  startPermissionLoading,
  setPermission,
  setError,
} from "./permissionXRol";
/**
  * Esta es una función de TypeScript React que recupera permisos para un rol determinado y envía acciones en función de si flagUserPermission es verdadero o falso.
  * @param {número | string} rol: el ID o el nombre del rol para el que se obtienen los permisos.
  * @param {boolean} flagUserPermission: una bandera booleana que indica si el permiso que se recupera es para un usuario específico o para un rol. Si se establece en verdadero, el permiso se recuperará para un usuario; de lo contrario, se recuperará para un rol.
  * @returns Una función que toma dos parámetros (rol y flagUserPermission) y devuelve una función asíncrona que distribuye acciones según el resultado de una llamada API.
  */
export const getPermission = (
  rol: number | string,
  flagUserPermission?: boolean
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startPermissionLoading());
    try {
      const { data } = await ColdexTestAPI.get<IApiResult<Generic>>(
        `adminUser/Permission/Permission?IdRol=${rol}`,
        {
          headers: { "Access-Control-Allow-Origin": "*" },
        }
      );
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_Permissions}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_Permissions}`,
            open: true,
          })
        );
      } else {
        if (flagUserPermission) {
          dispatch(
            setPermission({
              resultObject: data.resultObject,
              resultMessage: data.resultMessage,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_Permissions}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_Permissions}`,
          open: true,
        })
      );
    }
  };
};
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(
      setError({
        iserror: false,
        error: "",
        open: false,
        shorterror: "",
      })
    );
  };
};
