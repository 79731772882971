import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IRowDataColdexState } from '../../../utils/interfaces/store/IStore'
import { RowDataPublicColdex } from '../../../utils/types/RowDataColdex'
import { IPayloadSetdata, IPayloadSetError } from '../Interfaces'


const initialState: IRowDataColdexState  = {
  rowDateColdex: [],
  shortMessage: '',
  resultMessage: '',
  dataError: false,
  open: false,
  isLoading: false
}


export const rowDataPublicColdexSlice = createSlice({
  name: 'rowDataPublicColdex',
  initialState,
  reducers: {
    startLoading:(state)=>{
        state.isLoading=true
    },
    setRowDataPublicColdex:(state,{payload}:PayloadAction<IPayloadSetdata<Array<RowDataPublicColdex>>>)=>{
        state.isLoading=false
        state.rowDateColdex = payload.resultObject;
    },
    setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
      state.isLoading=false
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    endLoading:(state)=>{
      state.isLoading=false
    }
  },
})

// Action creators are generated for each case reducer function
export const {  startLoading,setRowDataPublicColdex,setError,endLoading} = rowDataPublicColdexSlice.actions

export default rowDataPublicColdexSlice.reducer