import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {  IPolls, IPollTypeState } from '../../../utils/interfaces/store/IStore';
import { IPayloadSetdata, IPayloadSetError } from '../Interfaces';

const initialState: IPollTypeState   = {
    polls: [],
    shortMessage: '',
    resultMessage: '',
    dataError: false,
    open: false,
    isLoading: false
}

export const pollTypeSlice = createSlice({
    name: 'PollTypes',
    initialState,
    reducers:{
        startLoadingPollType: (state) =>{
            state.isLoading = true;
        },
        setPollType:(state, {payload}:PayloadAction<IPayloadSetdata<Array<IPolls>>>)=>{
            state.isLoading = false;
            state.dataError = false;
            state.polls = payload.resultObject;
            state.resultMessage = payload.resultMessage!;
        },
        setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
            state.isLoading = false;
            state.dataError = payload.iserror;
            state.resultMessage = payload.error;
            state.shortMessage = payload.shorterror;
            state.open = payload.open!;
        }
    }
})

export const {startLoadingPollType, setPollType,setError} = pollTypeSlice.actions

export default pollTypeSlice.reducer
